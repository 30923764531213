import React from "react";
import CSS from "./Quotation.module.css";

import deleteIcon from '../../../assets/icons/delete.svg'
import editIcon from '../../../assets/icons/edit.svg'
import { Link } from "react-router-dom";

const Quotation = () => {

  return (
    <div className={CSS.mainQuotationContainer}>
      <div className={CSS.topContainer}>
        <div className={CSS.searchAndCalenderContainer}>

          {/* searchbar and the calender added here */}
          <div className={CSS.singleItemContainer}>
            <div className={CSS.headingClass}> Search Text </div>
            <input type="text" placeholder = "Search by quotation number" className={CSS.searchInput} />
          </div>

          <div className={CSS.singleItemContainer}>
            <div className={CSS.headingClass}> Start Date </div>
            <input type="date" className={CSS.dateInput} />
          </div>

          <div className={CSS.singleItemContainer}>
            <div className={CSS.headingClass}> End Date </div>
            <input type="date" className={CSS.dateInput} />
          </div>

        </div>

      {/* Buttons */}
        <div className={CSS.addQuotationButtonContainer}>
          <Link to={"/quotation/newquotation"}> <button className={CSS.addQuotationButton}> New Quote </button></Link>
          <button className={CSS.addQuotationButton}> Select Status </button>
        </div>  
      </div>

      <div className={CSS.tableContainer}>

        <table className={`${CSS["TableContainer"]} ${CSS["TableCustom"]}`}>
          <thead>
            <tr className={CSS.TableHead}>
              <th className={CSS.SerialNumber}>ID</th>
              <th className={CSS.TypeInput}>Quotation Date</th>
              <th className={CSS.TypeInput}>Agent Name</th>
              <th className={CSS.TypeInput}>No# of Pax</th>
              <th className={CSS.TypeInput}>Guest Name</th>
              <th className={CSS.TypeInput}>Status</th>
              <th className={CSS.TypeInput}>Payment Date</th>
              <th className={CSS.TypeInput}>Payment Method</th>
              <th className={CSS.TypeInput}>Actions</th>
            </tr>
          </thead>
          <tbody>
              <td className={CSS.SerialNumber}>1</td>
              <td className={CSS.TypeInput}>04/04/2024</td>
              <td className={CSS.TypeInput}>Awais Mohammad</td>
              <td className={CSS.TypeInput}>02</td>
              <td className={CSS.TypeInput}>Zeeshan</td>
              <td className={CSS.TypeInput}>Paid</td>
              <td className={CSS.TypeInput}>04/04/2024</td>
              <td className={CSS.TypeInput}>Bank Transfer</td>
              <td className={CSS.actionsColumn}>
                    <div className={CSS.actionsContainer}>
                      
                      {/* delete icon */}
                      <img src={deleteIcon} alt="delete" className={CSS.iconContainer}></img>

                      {/* edit icon */}
                      <img src={editIcon} alt="delete" className={CSS.iconContainer}></img>
                      
                    </div>
                  </td>
          </tbody>

        </table>
      </div>   
    </div>
  );
};

export default Quotation;
