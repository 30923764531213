import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


/* quotationItems: [
    {
        quotation_item_id: null,
        request_id: null,
        resource_type: null,//hotel's, sight seeing's id, transfer's id
        resource:{
            resource_id: null,
        } null,//particulars id which will be selected from the particulars
        date: '',
        cost_per_pax: null,//will be calculated on run time  formula = (price/no of pax)
        
        item_accomotation: {
            no_of_nights: null,
            check_in: '',//dates along with time
            check_out: '',
        }
        rooms: [
            {
                room_id: null, //room id.
            }
        ]
    }
*/

const quotationSlice = createSlice({
    name: "quotations",

    initialState: {
        quotation_id: null,
        
        quotationItems: [
            {
                quotation_item_id: null,
                request_id: null,
                resource_type: null, // hotel's, sight seeing's id, transfer's id
                resource: {
                    resource_id: null,
                    resource_name: '', // name of the resource
                    
                }, // particulars id which will be selected from the particulars
                date: '',
                cost_per_pax: null, // will be calculated on run time formula = (price/no of pax)
    
                item_accommodation: {
                    no_of_nights: null,
                    check_in: '', // dates along with time
                    check_out: '',
                },
                rooms: [
                    {
                        room_id: null, // room id.
                    }
                ], 
    
                status: 'idle' | 'loading' | 'succeeded' | 'failed',
                error: null,
            },
        ],
    },
    
    reducers: {

        addQuotationItem: (state, action) => {
            state.quotationItems.push(action.payload);
        },

        //setting the resource object especially for the particulars.
        setResourceType: (state, action) => {
            state.quotationItems.resource = action.payload;
        },

        setParticulars: (state, action) => {
            state.quotationItems[action.payload.index].resource = action.payload.data;
        },
    
        setDate : (state, action) => {
            state.quotationItems.date = action.payload;
        },

        setPrice: (state, action) => {
            state.quotationItems.cost_per_pax = action.payload;
        },

        extraReducers: (builder) => {
            
        }

    },
});


export const selectQuotationItems = (state) => state.quotations.quotationItems;
export default quotationSlice.reducer;
export const { addQuotationItem, setResourceType, setParticulars, setDate, setPrice } = quotationSlice.actions;



