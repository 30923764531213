import React, { useState } from 'react';
import CSS from './QuotationDetailCard.module.css';
import Select from 'react-select';
import DeleteIcon from '../../../assets/icons/delete.svg';
import {getHotelsAction} from '../../../services/client/hotel/index'
import { useSelector } from 'react-redux';
import {getSightSeeingsAction} from '../../../services/client/sightseeings/index'
import {GetTransferApi} from '../../../services/client/transfers/index'


import { TourDetailsData, items } from '../../../services/client/utils';

//redux store data
import { getTransfersAction } from '../../../services/client/transfers';
import { setParticularsAction, setResourceTypeAction, addQuotationItemAction, setDateAction, setPriceAction } from '../../../services/client/quotations';

import { selectHotels } from '../../../services/features/hotels/hotelSlice';
import { selectSightseeings } from '../../../services/features/sightseeings/SightSeeingsSlice';
import { selectTransfers } from '../../../services/features/transfers/transferSlice';

const QuotationDetailCards = ({ index }) => 
{

    const [selectedOption, setSelectedOption] = useState(null);
    
    //id Item
    //0  hotel
    //1  Room
    //2 Sight Seeing
    //3 Transfers
    //4 Airports
    const hotelsData = useSelector(selectHotels);
    const sightseeingsData = useSelector(selectSightseeings);
    const transfersData = useSelector(selectTransfers);

    let particularsData = [];   

    

    //the selected option will be fetched from the redux store
    switch(selectedOption){
        case '0':
            particularsData = hotelsData;

           
                    particularsData = particularsData.map(item => ({ value: item.id, label: item.name }));
        
                

            break;
        case '2':
            particularsData = sightseeingsData;

           
                particularsData = particularsData.map(item => ({ value: item.id, label: item.name }));
        
        
            break;
        case '3':
            particularsData = transfersData;

            particularsData = particularsData.map(item => ({ value: item.name, label: item.name }));
            break;
        default:
            particularsData = [];
            break;
        }   
        
       
        

    //handling the change of the tours according to the selected option.
    const handleToursChange = (selectedOption) => {
    
        setSelectedOption(selectedOption.value);

        if(selectedOption.value === '0')
        {
            getHotelsAction();
            
        }
        else if(selectedOption.value === '2')
        {
            getSightSeeingsAction();
        }
        else if(selectedOption.value === '3')
        {
            getTransfersAction();
            
        }   
        console.log(particularsData)
    }
    
    //setting the value inside the redux store
    const handleParticularsChange = (selectedOption) => {

        const resource ={
            index: index,
            data: selectedOption 
        }

        setParticularsAction(resource);
    }

    //handle date
    const handleDate = (e) => {
        setDateAction(e.target.value);
    }


    return (
        <div className={CSS.mainQuotationCardContainer}>    
            <div className={CSS.SerialNumberContainer}>
                <div className={CSS.SerialNumber}>
                    {index + 1}
                </div>
            </div>

            <div className={CSS.tourDetailsContainer}>
                <div className={CSS.tourDetails}>
                    <Select
                        options={TourDetailsData}
                        onChange={handleToursChange}
                        placeholder="Select tours"
                    />
                </div>
            </div>

            <div className={CSS.particularsContainer}>
                <div className={CSS.particulars}>
                    <Select
                        options={particularsData}   
                        onChange={handleParticularsChange}
                        placeholder="Particulars"
                    />
                </div>
            </div>
        
            <div className={CSS.DateContainer}>
                <div className={CSS.Date}>
                    <input type="date" className={CSS.dateInput} onChange={handleDate}/> 
                </div>
            </div>

            <div className={CSS.PriceContainer}>
                <div className={CSS.Price}>
                    <Select
                        options={TourDetailsData}
                        placeholder="Price"
                    />
                </div>
            </div>
            <div className={CSS.actionsContainer}>
                <div className={CSS.Price}>
                    <img src={DeleteIcon} alt="delete" className={CSS.iconClass}/>
                </div>
            </div>
        </div>
    );
};

const QuotationDetailCard = () => {
    const [numComponents, setNumComponents] = useState(0); 

    const handleAddMore = () => {

        addQuotationItemAction(null);
        setNumComponents(prevNumComponents => prevNumComponents + 1); 
    };

    return (
        <div className={CSS.newContainerMain}>
            {[...Array(numComponents)].map((_, index) => (
                <QuotationDetailCards key={index} index={index} />
            ))}
        
            <div className={CSS.addMoreButtonContainer}>
                <button className={CSS.addMoreButton} onClick={handleAddMore}>Add</button>
            </div>
        </div>
    );
};

export default QuotationDetailCard;
