import React from 'react'
import HotelCard from "../../shared/Card/Card";
import AddButton from "../../shared/AddButton/AddButton";
import './Hotels.css'
import { useEffect } from 'react';
import { selectLoading, selectHotels } from '../../../services/features/hotels/hotelSlice';
import { useSelector } from 'react-redux';
import ProgressLoader from '../../shared/ProgressLoader/ProgressLoader';
import { getHotelsAction } from '../../../services/client/hotel';


const Hotels = () => {

  const hotels = useSelector(selectHotels)
  const loading = useSelector(selectLoading)

    //calling the API to fetch the hotels.
    useEffect(() => {
      getHotelsAction()
    }
    , [])

    const handleEditFunction = (id) => {
      console.log('Edit button clicked', id)
    }

    const handleDeleteFunction = (id) => {
      console.log('Delete button clicked', id)
    }

  return (
    <div>
    {/* call the add button component and set onclick */}
    
      <AddButton link={'/hotels/addhotel'}/>
    
    {/* Mapping through HotelsData and rendering HotelCard for each hotel */}
    {
      loading ? <ProgressLoader size={'lg'}/> : 
      hotels.length === 0 ? <center><h1 className='notFoundTitle'>No Hotels Found</h1></center> : 
      hotels.length > 0 && 
      hotels.map((hotel, index) => (
        <HotelCard
          key={index}
          title={'Hotel'}
          image={hotel.images.length > 0 ? hotel.images[0].file : ''}
          name={hotel.name}
          city={hotel.city}
          detailsLink={'/hotels/details/' + hotel.id}
          id={hotel.id}
          type={'hotel'}
        />
      ))
    }
  
  </div>
)}

export default Hotels