import React, { useState } from 'react';
import './RoomsCard.css';
import ArrowIcon from '../../../assets/icons/arrow-square-down.svg';
import { RoomTypes } from '../../../services/client/utils';
import { useSelector } from 'react-redux';
import { selectRooms, setRooms } from '../../../services/features/hotels/hotelSlice';
import { addRoomAction, updateRoomsAction } from '../../../services/client/hotel/create-hotel';
import AddButton from '../../../assets/icons/add-button.svg';

const RoomCard = ({ roomtype, roomprice, roomcapacity, index }) => {

  const [showDropdown, setShowDropdown] = useState(false);

  let Values = RoomTypes.find((item)=>(item.type === roomtype));

  //checking for null values.
  if(!Values){
    Values={value: 'Room Type'};
  }
  
  //checking for null values of the roomtype.
  if(!roomtype){
    roomtype = 'Room Type';
  }

  //checking for null values of the price.
  if(!roomprice){
    roomprice = 'Price';
  }

  //checking for null values of the capacity.
  if(!roomcapacity){
    roomcapacity = 'Capacity';
  }

  //states for the type, price and capacity of the room.  
  const [selectedTypeID, setSelectedTypeID] = useState(Values.type);
  const [selectedType, setSelectedType] = useState(Values.value);
  const [price, setPrice] = useState(roomprice);
  const [capacity, setCapacity] = useState(roomcapacity);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  //select type function
  const handleTypeSelect = (type) => {
    setSelectedType(type.value);
    setShowDropdown(false);

    setSelectedTypeID(type.type);
    addRoomsData({type: type.value, cost_per_night: price, capacity: capacity});
  };

  //checking price of the hotel room per night.
  const handlePriceChange = (e) => {  

    const value = e.target.value;
    if (value === '' || (parseFloat(value) > 0 && !isNaN(value))) {
      setPrice(value);
    }
    else{
      setPrice('Price');
    }

    addRoomsData({type: selectedTypeID, cost_per_night: value, capacity: capacity});
  };


  //checking capacity of the hotel room.
  const handleCapacityChange = (e) => {
    const value = e.target.value;

    if (value === '' || (parseInt(value) > 0 && !isNaN(value))) {
      setCapacity(value); 
    }
    else{
      setCapacity('Capacity');
    }

    addRoomsData({type: selectedTypeID, cost_per_night: price, capacity: value});
  };


  //get the data from the stated and update the state of the room.
  const addRoomsData = (data) => {
    const roomsData = {
      data: data,
      index: index
    };

    
    updateRoomsAction(roomsData);
  };

  return (
    <div className='container-roomCard'>
      <div className='background-container'>
        <div className='toggle-container' onClick={toggleDropdown}>
          <span className='room-type'>{selectedType} 
            <img 
              src={ArrowIcon} 
              alt="Arrow" 
              className={`arrow-icon ${showDropdown ? 'rotate' : ''}`} 
            />
          </span>
        </div>
        {showDropdown && (
          <div className='dropdown-menu'>
            {RoomTypes.map((type, index) => (
              <div key={index} className='dropdown-item' onClick={() => handleTypeSelect(type)}>
                {type.value}
              </div>
            ))}
          </div>
        )}

        <input type='text'  placeholder={price} className='room-price-capacity'  onChange={(e) => handlePriceChange(e)}></input>
        <input type='text' placeholder={capacity} className='room-price-capacity' onChange={(e) => handleCapacityChange(e)}></input>
      </div>
    </div>
  );
};


//RoomsCard component
const RoomsCard = () => {

  const rooms = useSelector(selectRooms);

  //checking for null values.
  if (!rooms) {
    return []; 
  }

  //adding a new roon in the rooms array.
  const handleAddRoom = () => {
    
    addRoomAction({id: null, type: '', cost_per_night: '', capacity: ''});

  };

  
  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>

        {rooms.map((room, index) => (
          <div key={index} style={{ marginRight: '10px', marginBottom: '10px' }}>
            {
              <RoomCard 
                roomtype={room.type} 
                roomprice={room.cost_per_night} 
                roomcapacity={room.capacity}
                index={index}
            />
            }
          </div>

        ))}
        {        
          <div className='button-container-roomscard'>
            <img src={AddButton} alt='add-button' className='image-icon-container' onClick={handleAddRoom}></img>
          </div>  
        }
        
      </div>
    </div>
  );
};

export default RoomsCard;
