import { fetchHotels, fetchHotel, updateHotelName, updateCity } from '../../features/hotels/hotelSlice';
import { store } from '../../features/store'
import { setRoomsAction } from './create-hotel';
import { deleteHotel } from '../../features/hotels/hotelSlice';
import { updateContractExpiry, updateContractStart } from '../../features/hotels/hotelSlice';

export const getHotelsAction = () =>{
    
    store.dispatch(fetchHotels());
}

//fetch Hotel action
export const getHotelAction = (id) =>{

    setRoomsAction([]);
    store.dispatch(fetchHotel(id));
}

//update hotel name action.
export const updateHotelNameAction = (data) => {

    store.dispatch(updateHotelName(data));
}

//update city action.
export const updateCityAction = (data) => {

    store.dispatch(updateCity(data));
}

//delete hotel action.
export const deleteHotelAction = (id) => {

    store.dispatch(deleteHotel(id));
}

export const updateContractExpiryAction = (data) => {
    
    store.dispatch(updateContractExpiry(data));
}

export const updateContractStartAction = (data) => {
    
    store.dispatch(updateContractStart(data));
}
