import { DefaultLayout, AuthLayout } from "./layout";

// Route Views
import Login from "./components/views/Login/Login";
import Hotels from "./components/views/Hotels/Hotels";
import AddHotel from "./components/views/Hotels/AddHotel/AddHotel";
import SightSeeings from "./components/views/Sight Seeings/SightSeeings";
import AddSightSeeing from "./components/views/Sight Seeings/AddSightSeeing/AddSightSeeing";
import HotelDetails from "./components/views/Hotels/HotelDetails/HotelDetails";
import TransferListing from "./components/views/TransferListing/TransferListing";
import CreateTransfer from "./components/views/TransferListing/CreateTransfer";
import SightSeeingDetails from "./components/views/Sight Seeings/SightSeeingDetails/SightSeeingDetails";
import TransferDetails from "./components/views/TransferListing/TransferDetails";
import WorkUnderProgress from "./components/views/WorkUnderProgress/WorkUnderProgress";
import Request from "./components/views/Request/Request";
import { CreateRequest } from "./components/views/Request/CreateRequest/CreateRequest";
import RequestDetails from "./components/views/Request/requestDetails/RequestDetails";
import Agents from "./components/views/Agents/Agents";
import AddAgent from "./components/views/Agents/Add Agent/AddAgent";
import AgentDetails from "./components/views/Agents/Agent Details/AgentDetails";
import  Operations  from "./components/views/Operations/Operations";
import OperationDetails from "./components/views/Operations/OperationsDetails/OperationsDetails";
import Quotation from "./components/views/Quotation/Quotation";
import NewQuotation from "./components/views/Quotation/New Quotation/NewQuotation";
import QuotationDetails from "./components/views/Quotation/QuotationDetails/QuotationDetails";

//routes defined for the components.
const routes = [
  {
    path: "/",
    component: <Login />,
    layout: <AuthLayout />,
  },
  {
    path: "/login",
    component: <Login />,
    layout: <AuthLayout />,
  },
  {
    path: "/hotels",
    component: <Hotels />,
    layout: <DefaultLayout title={"Hotels"} />,
  },
  {
    path: "/overview",
    // component:<Overview />,
    component: <WorkUnderProgress />,
    layout: <DefaultLayout title={"Overview"} />,
  },
  {
    path: "/request",
    component: <Request />,
    layout: <DefaultLayout title={"Request"} />,
  },
  {
    path: "/request/details/:id",
    component: <RequestDetails />,
    layout: <DefaultLayout title={"Request Details"} />,
  },
  {
    path: "/request/createrequest",
    component: <CreateRequest />,
    layout: <DefaultLayout title={"Create Request"} />,
  },
  {
    path: "/hotels/addhotel",
    component: <AddHotel />,
    layout: <DefaultLayout title={"Add Hotel"} />,
  },
  {
    path: "/quotation",
    component: <Quotation/>,
    layout: <DefaultLayout title={'Quotation'}/>

  },
  {
    path: "/sightseeings",
    component: <SightSeeings />,
    layout: <DefaultLayout title={"Sight Seeing's"} />,
  },
  {
    path: "/sightseeings/addsightseeings",
    component: <AddSightSeeing />,
    layout: <DefaultLayout title={"New Sight Seeing"} />,
  },
  {
    path: "/transfers",
    component: <TransferListing />,
    layout: <DefaultLayout title={"Transfers"} />,
  },
  {
    path: "/roe",
    component: <WorkUnderProgress />,
    layout: <DefaultLayout title={"ROE"} />,
  },
  {
    path: "/helpandinformation",
    component: <WorkUnderProgress />,
    layout: <DefaultLayout title={"Help and Information"} />,
  },
  {
    path: "/logout",
    component: <WorkUnderProgress />,
    layout: <DefaultLayout title={"Login"} />,

    // component: <WorkUnderProgress/>,
    // layout: <DefaultLayout title={'ROE'}/>
  },
  {
    path: "/helpandinformation",
    component: <WorkUnderProgress/>,
    layout: <DefaultLayout title={'Help and Information'}/>
  },
  {
    path: "/logout",
    component: <WorkUnderProgress/>,
    layout: <DefaultLayout title={'Login'}/>

  },
  {
    path: "/hotels/details/:id",
    component: <HotelDetails />,
    layout: <DefaultLayout title={"Hotel Details"} />,
  },
  {
    path: "/create/transfer",
    component: <CreateTransfer />,
    layout: <DefaultLayout title={"Create Transfer"} />,
  },
  {
    path: "/sightseeings/details/:id",
    component: <SightSeeingDetails />,
    layout: <DefaultLayout title={"Sight Seeing"} />,
  },
  {
    path: "/transfers/details/:id",
    component: <TransferDetails />,
    layout: <DefaultLayout title={"Transfer Details"} />,
  },
  {
    path: "/agents",
    component: <Agents/>,
    layout: <DefaultLayout title={'Agents'}/>
  },
  {
    path: "/agent/addagent",
    component: <AddAgent/>,
    layout: <DefaultLayout title={'Add Agent'}/>
  },
  {
    path: "/agents/details/:id",
    component: <AgentDetails/>,
    layout: <DefaultLayout title={'Agent Details'}/>
  },
  {
    path: "/operations",
    component: <Operations/>,
    layout: <DefaultLayout title={'Operations'}/>
  },
  {
    path:"/operations/details",
    component:<OperationDetails/>,
    layout:< DefaultLayout title={'Operations'}/>
  },
  {
    path: "/quotation/newquotation",
    component: <NewQuotation/>,
    layout: <DefaultLayout title={'New Quotation'}/>
  },
  {
    path: "/quotation/details/:id",
    component: <QuotationDetails/>,
    layout: <DefaultLayout title={'Quotation Details'}/>
  }

];

export default routes;
