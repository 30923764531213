
import React, { useEffect, useState } from "react";
import "./SelectTo.css"; 
import ArrowIcon from "../../../assets/icons/search-normal.png";
import { useSelector } from "react-redux";
import {
  selectLoading as selectHotelLoading,
  selectHotels,
} from "../../../services/features/hotels/hotelSlice";
import { getHotelsAction } from "../../../services/client/hotel";
import {
  selectLoading as selectSightseeingsLoading,
  selectSightseeings,
} from "../../../services/features/sightseeings/SightSeeingsSlice";
import { getSightSeeingsAction } from "../../../services/client/sightseeings";
import { getAirportsAction } from "../../../services/client/airports";
import { selectAirports } from "../../../services/features/airports/airportSlice";

function SelectTo({ selectedType, onToValueChange, currentValue = '' }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const hotels = useSelector(selectHotels);
  const loading = useSelector(selectHotelLoading);
  const sightseeings = useSelector(selectSightseeings);
  const airports = useSelector(selectAirports);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleItemSelect = async (item) => {
    setSelectedItem(item);
    setShowDropdown(false);

    if (onToValueChange) {
      onToValueChange(item);
    }
  };

  let itemsArray = [];
  switch (selectedType) {
    case "Airport":
      itemsArray = airports || [];
      break;
    case "Hotel":
      itemsArray = hotels || [];
      break;
    case "Sightseeings":
      itemsArray = sightseeings || [];
      break;
    default:
      break;
  }

  const filteredItems = itemsArray.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );


  return (
    <div>
      <div className="SelectContainer">
        <div className="toggle-container" onClick={toggleDropdown}>
          <span className="SelectType">
            {
              currentValue ? currentValue :
              selectedItem ? selectedItem.name : `Select ${selectedType}`
            }
            <img
              src={ArrowIcon}
              alt="Arrow"
              className={`Arrow ${showDropdown ? "rotate" : ""}`}
            />
          </span>
        </div>
        {showDropdown && (
          <div className="dropdown-menu">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {!loading &&
              filteredItems.map((item, index) => (
                <div
                  key={index}
                  className="dropdown-item"
                  onClick={() => handleItemSelect(item)}
                >
                  {item.name}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default SelectTo;
