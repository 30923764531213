import React from 'react';
import './AddButton.css';
import addButton from '../../../assets/icons/add-button.svg';
import { Link } from 'react-router-dom';

const AddButton = ({link}) => {
    return (

        <div className='add-button-container'>
             <input type='text' placeholder='Search by city,code' className='searchBarInside'></input>
            <Link to={link} className='addButtonLink'>
                <button className='addButton'>Add</button>
                <img src={addButton} alt='addButton' className='buttonIcon'/>
            </Link>
        </div>
    );
}

export default AddButton;
