// import { setTourDetails, setParticulars, setDate, addQuotationItem, setPrice } from "../../features/quotationSlice/quotationSlice";
import { store } from "../../features/store";
import { addQuotationItem, setResourceType, setParticulars, setDate, setPrice } from "../../features/quotationSlice/quotationSlice";

export const addQuotationItemAction = (quotationItem) => {
    store.dispatch(addQuotationItem(quotationItem));
}

export const setResourceTypeAction = (resource) => {
    store.dispatch(setResourceType(resource));
}

export const setParticularsAction = (particulars) => {
    store.dispatch(setParticulars(particulars));
}

export const setDateAction = (date) => {
    store.dispatch(setDate(date));
}

export const setPriceAction = (price) => {
    store.dispatch(setPrice(price));
}

