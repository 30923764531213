import React from 'react';
import CSS from "./Operations.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from 'react-router-dom';
import img from "../../../assets/icons/Rectangle 193.svg";
import SearchIcon from '../../../assets/icons/search.png';
import lineIcon from "../../../assets/icons/Line 52.svg";

const Operations = () => {
  return (
    <div className={CSS.Container}>
      <div className={CSS.TopContainer}>
        <img src={img} alt="search" className={CSS.MainImg} />
        <div className={CSS.ManiBox}>
          <div className={CSS.searchBarsContainer}>
            <img src={SearchIcon} alt="search" className={CSS.searchIcon} />
            <img src={lineIcon} className={CSS.Line1} />
            <input type="text" placeholder="Agent Name" className={CSS.searchBar} />
            <img src={SearchIcon} alt="search" className={CSS.searchIcon1} />
            <img src={lineIcon} className={CSS.Line2} />
            <input type="text" placeholder="Guest Name" className={CSS.searchBar} />
            <img src={SearchIcon} alt="search" className={CSS.searchIcon2} />
            <img src={lineIcon} className={CSS.Line3} />
            <input type="text" placeholder="Date" className={CSS.searchBar} />
            <button className={CSS.SearchButton}>Search</button>
          </div>
        </div>
      </div>
      <div className={`${CSS.CustomTableContainer}`}>
        <table className={`${CSS.TableContainer} ${CSS.TableCustom}  table table-striped`}>
          <thead>
            <tr className={CSS.TableHead}>
              <th className={CSS.ID}>ID</th>
              <th className={CSS.DataTable}>Guest Name</th>
              <th className={CSS.DataTable}>Agent Name</th>
              <th className={CSS.DataTable}>Booking Name</th>
              <th className={CSS.Status}>Status</th>
              <th className={CSS.Traveldate}>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={CSS.ID}><Link to={"/operations/details"}>B112</Link></td>
              <td className={CSS.DataTable}>01/01/2024</td>
              <td className={CSS.DataTable}>Cultural voyages</td>
              <td className={CSS.DataTable}>05</td>
              <td className={CSS.CustomStatus}>Done</td>
              <td className={CSS.Traveldate}>05/01/2024</td>
            </tr>
            <tr>
              <td className={CSS.ID}><Link to={"/operations/details"}>B113</Link></td>
              <td className={CSS.DataTable}>01/01/2024</td>
              <td className={CSS.DataTable}>Cultural voyages</td>
              <td className={CSS.DataTable}>05</td>
              <td className={CSS.CustomStatus}>Done</td>
              <td className={CSS.Traveldate}>05/01/2024</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Operations;
