import React, { useState } from "react";
import "./To.css";
import ArrowIcon from "../../../assets/icons/arrow-square-down.png";
import { FromPlace } from "../../../services/client/utils";

const To = ({ onSelectChange, type='' }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedType, setSelectedType] = useState(type);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleTypeSelect = (type) => {
    setSelectedType(type);
    setShowDropdown(false);

    // Call the onSelectChange function with the selected type
    if (onSelectChange) {
      onSelectChange(type);
    }
  };

  return (
    <div>
      <div className="ToContainer">
        <div onClick={toggleDropdown}>
          <span className="To-Type">
            {selectedType}
            <img
              src={ArrowIcon}
              alt="Arrow"
              className={`Arrow-Icon ${showDropdown ? "rotate" : ""}`}
            />
          </span>
        </div>
        {showDropdown && (
          <div className="dropdown-menu">
            {FromPlace.map((type, index) => (
              <div
                key={index}
                className="dropdown-item"
                onClick={() => handleTypeSelect(type.value)}
              >
                {type.value}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default To;
